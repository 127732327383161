import { createGlobalStyle } from 'styled-components';
import lato300 from '../assets/fonts/Lato-Thin.woff';
import lato400 from '../assets/fonts/Lato-Regular.woff';
import lato500 from '../assets/fonts/Lato-Medium.woff';
import lato700 from '../assets/fonts/Lato-Bold.woff';

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #000000;
    --greymi: #ddd;
    --litegreen: #9CB783;
    --green: #4D7853;
    --greenrgb: 77, 120, 83;
    --darkgreen: #425E4B;
    --yellow: #ff8800;
    --offwhite: #e6e6e6;
    --offwhitergba: 242, 242, 242;
    --white: #fff;
    --purple: #6750A4;
    --gray: #1A422B;
    --litegray: #C4C4C4;
  }
  /* Lato-300 */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url(${lato300});
  }
  /* Lato-400 */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url(${lato400});
  }
  /* Lato-500 */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    src: url(${lato500});
  }
  /* Lato-700 */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url(${lato700});
  }

  html {
    background-size: 810px;
    background-attachment: fixed;
    font-family: 'Lato';
    font-size: 62.5%;
    color: var(--black);
    scroll-behavior: smooth;
    box-sizing: border-box !important;
  }
  
  *, *::before, *::after {
    box-sizing: inherit;
  } 

  body {
    font-size: 1.6rem;
    scroll-behavior: smooth;
    margin: 0;
  }

  .thumbs-wrapper.axis-vertical {
    display: flex;
    justify-content: center;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: var(--red) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    
    background-color: var(--red) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  h1, h2, h3, h4, h5, h6 {
    color: var(---black);
    opacity: 0.7;
    padding: 0;
  }

  img {
    max-width: 100%;
  }

  a {
    text-decoration: none;
    transition: opacity 0.2s ease-out;
    color: var(--black);
    font-weight: 500;
  }
  a:hover {
    opacity: 0.69;
    color: var(--yellow);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  span, i {
    opacity: 0.7;
  }

 span.ant-picker-super-next-icon, span.ant-picker-super-prev-icon {
  display: none !important;
 } 
`;

export default GlobalStyles;
