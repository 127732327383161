exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-create-lake-js": () => import("./../../../src/pages/create-lake.js" /* webpackChunkName: "component---src-pages-create-lake-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-lowiska-js": () => import("./../../../src/pages/lowiska.js" /* webpackChunkName: "component---src-pages-lowiska-js" */),
  "component---src-pages-nieudana-platnosc-js": () => import("./../../../src/pages/nieudana-platnosc.js" /* webpackChunkName: "component---src-pages-nieudana-platnosc-js" */),
  "component---src-pages-onas-js": () => import("./../../../src/pages/onas.js" /* webpackChunkName: "component---src-pages-onas-js" */),
  "component---src-pages-paneladmin-js": () => import("./../../../src/pages/paneladmin.js" /* webpackChunkName: "component---src-pages-paneladmin-js" */),
  "component---src-pages-partnerzy-js": () => import("./../../../src/pages/partnerzy.js" /* webpackChunkName: "component---src-pages-partnerzy-js" */),
  "component---src-pages-pass-js": () => import("./../../../src/pages/pass.js" /* webpackChunkName: "component---src-pages-pass-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-reservation-details-js": () => import("./../../../src/pages/reservation-details.js" /* webpackChunkName: "component---src-pages-reservation-details-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-rezerwacja-niedostepna-js": () => import("./../../../src/pages/rezerwacja-niedostepna.js" /* webpackChunkName: "component---src-pages-rezerwacja-niedostepna-js" */),
  "component---src-pages-strona-potwierdzenia-js": () => import("./../../../src/pages/strona-potwierdzenia.js" /* webpackChunkName: "component---src-pages-strona-potwierdzenia-js" */),
  "component---src-pages-test-page-2-js": () => import("./../../../src/pages/testPage2.js" /* webpackChunkName: "component---src-pages-test-page-2-js" */),
  "component---src-pages-test-page-js": () => import("./../../../src/pages/testPage.js" /* webpackChunkName: "component---src-pages-test-page-js" */),
  "component---src-pages-userpanel-js": () => import("./../../../src/pages/userpanel.js" /* webpackChunkName: "component---src-pages-userpanel-js" */),
  "component---src-pages-wybierz-wojewodztwo-js": () => import("./../../../src/pages/wybierz-województwo.js" /* webpackChunkName: "component---src-pages-wybierz-wojewodztwo-js" */),
  "component---src-templates-city-js": () => import("./../../../src/templates/city.js" /* webpackChunkName: "component---src-templates-city-js" */),
  "component---src-templates-lake-js": () => import("./../../../src/templates/lake.js" /* webpackChunkName: "component---src-templates-lake-js" */),
  "component---src-templates-voivodeship-js": () => import("./../../../src/templates/voivodeship.js" /* webpackChunkName: "component---src-templates-voivodeship-js" */)
}

